<template>
  <div>
    <ion-text class="ion-text-center" color="primary">
      <h2>JUNTAS RECEPTORAS DEL VOTO</h2>
    </ion-text>
    <ion-text class="ion-text-center" color="secondary" v-if="whereWeAre">
      <h3>{{whereWeAre}}</h3>
    </ion-text>
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col
          size="12"
          size-lg="4"
          size-md="6"
          @click="$router.replace(v.route)"
          v-for="v in juntas"
          :key="v.id"
        >{{ v.name }}</ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script lang="ts">
import { IonGrid, IonCol, IonRow, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
import { territorios } from "@/modules/cyanRegions";
import unreact from "@/modules/unreact";

interface ElementoMapa {
  id: number;
  name: string;
  route: string;
}

export default defineComponent({
  name: "JuntaPicker",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    IonText
  },
  data() {
    return {
      seed: unreact(this.seedJP + "") ?? "",
      centro: unreact(this.centroJP + "") ?? "0"
    };
  },
  computed: {
    whereWeAre: function(): string {
      const t = (territorios as any)[this.seed] || {};
      const lugar = t ? t.nombre + ", " + t.nombrePadre : "";
      if (this.datosCentro)
        return this.datosCentro.nombre + (lugar ? ' ('+lugar+')' : '');
      return lugar;
    },
    acj: function(): any {
      const _acj = (this as any).$store.state.allowedCentrosJuntas;
      if (!_acj || !_acj[this.seed]) return {};
      return _acj[this.seed];
    },
    datosCentro: function(): any {
      if (
        !this.acj ||
        !this.acj.datosCentros ||
        !this.acj.datosCentros[this.centro]
      )
        return {};
      return this.acj.datosCentros[this.centro];
    },
    juntas: function(): ElementoMapa[] {
      const fracaso = [
        {
          id: -1,
          name: "??",
          route: "/home"
        }
      ];
      
      const acj = this.acj;

      if (!acj || !acj.mapaCentrosJuntas || !acj.mapaCentrosJuntas[this.centro])
        return fracaso;

      const mj = acj.mapaCentrosJuntas[this.centro];

      if (mj.length == 0) return fracaso;

      const r: ElementoMapa[] = [];
      let idx;
      let id = 1;

      for (idx of mj) {
        const dj = acj.datosJuntas[idx];

        r.push({
          id: id++,
          name: "JRV " + dj.nombre,
          route: this.nextRoute + this.seed + "/" + idx
        });
      }

      return r;
    }
  },
  props: {
    seedJP: {
      type: String,
      default: "SV"
    },
    centroJP: {
      type: String,
      default: "0"
    },
    nextRoute: {
      type: String
    },
    onlyFull: {
      type: Boolean,
      default: false
    },
    ifFull: {
      type: String,
      default: ""
    },
    ifSingleJunta: {
      type: String,
      default: ""
    }
  },
  methods: {}
});
</script>

<style scoped>
ion-col {
  --ion-grid-column-padding: 5px;
  font-weight: 500;
  cursor: pointer;
  color: var(--ion-color-dark);
  text-align: center;
  border-radius: 5px;
}
ion-col:hover {
  background: var(--cyan-lighter-background);
}
</style>